<template>
    <div v-if="status">
        <IndependentAcceleratorsView />
        <ReportTemplate :table-props="kafkaColumns" :project-param-id="projectParamId" :jms-param-id="jmsParamId" />
    </div>
</template>

<script>
import ReportTemplate from "@/components/ReportTemplate.vue";
import { pageViews, type } from "../utils/util";
import Store from "../vuex/store.js";
import IndependentAcceleratorsView from "./IndependentAcceleratorsView.vue";

export default {
    name: pageViews.KAFKACONFIGGENERATORVIEW,
    methods: {
        setValuesToStore(type) {
            Store.commit("setType", type);
        },
    },
    mounted() {
        this.setValuesToStore(type.KAFKA);
    },
    components: {
      ReportTemplate,
      IndependentAcceleratorsView
    },
    data() {
        return {
        status: true,
        uploadConfigs: {
            action: "generator",
            actionLabel: "Generator",
            title: "Kafka Config Generator",
            type: type.KAFKA,
            accept: ".zip",
        },
        kafkaColumns: {
            type: type.KAFKA,
            isMultiSelectionAllowed: true,
            defaultSortOrder: {name: "desc", label: "Descending"},
            defaultSortColumn: {name: "requestDate", label: "Request Date"},
            configs: [
            {
              name: "jmsId",
              label: "JMS ID",
              order: 2,
            },
            {
                name: "requestDate",
                label: "Request Date",
                order: 1,
                sort: true,
                type: "date",
                search: false,
                default: true
            },
            {
                name: "fileName",
                label: "File Name",
                order: 4,
                sort: true,
                checked: true,
                default: true,
                textAlign: "left",
            },
            {
                name: "status",
                label: "Status",
                order: 5,
                sort: true,
                default: true,
                search: false
            },
            {
                name: "lastModifiedDate",
                label: "Last Modified Date",
                order: 6,
                sort: true,
                type: "date",
                search: false
            },
            {
                name: "topicCount",
                label: "No of Topics",
                order: 7,
                sort: true,
                search: false,
                default: true
            },
            {
                name: "queueCount",
                label: "No of Queues",
                order: 8,
                sort: true,
                search: false,
                default: true
            },
            {
                name: "totalTopics",
                label: "Total Queues & Topics",
                order: 9,
                search: false,
                sort: true,
            },
            {
                name: "duplicateTopics",
                label: "Duplicate Queues & Topics",
                order: 10,
                search: false,
                sort: true,
            },
            {
                name: "failCount",
                label: "No of Failed Topics",
                order: 11,
                search: false,
                sort: true,
            },
            {
                name: "failedTopics",
                label: "Failed Topics",
                order: 12,
                search: false,
                textAlign: "left",
                textOverflowStyle: "ellipsis",
            },
            {
                name: "remarks",
                label: "Remarks",
                order: 13,
                search: false,
                textAlign: "left",
                textOverflowStyle: "ellipsis",
            }
            ],
            actions: {
            view: {
                label: 'View Output'
            },
            download: {
                label: 'Download'
            },
            }
        }  
        };
    },
    props: ["projectParamId", "jmsParamId"],
}

</script>
