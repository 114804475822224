<template>
  <!-- <MenuBar /> -->
  <ConfirmModal :popup="popup" @executePrimaryAction="handlePrimaryAction" ref="openModalBox" />

  <div v-if="isGridVisible" class="container-fluid mt-1 px-1">
    <div class="row">
      <div class="col">
        <!-- <div class="row"> -->
        <!--Revamp panel starts-->
        <div class="report-filter-bar row mb-1">
          <div class="col d-flex gap-2 justify-content">
            <!--Columns Dropdown-->
            <button type="button" class="btn btn-primary filterButton dropdown-toggle btn-sm" data-bs-toggle="dropdown"
              aria-expanded="false">
              Columns
            </button>
            <ul class="dropdown-menu fade">
              <li>
                <a class="dropdown-item" href="#">
                  <label>
                    <input type="checkbox" :value="'*'" v-model="selectedColumns" @change="handleColumnChange('*')" />
                    Select All
                  </label>
                </a>
              </li>
              <hr class="dropdown-divider" />
              <li v-for="(column, index) in tableConfigs" :key="index">
                <a class="dropdown-item" href="#">
                  <label>
                    <input type="checkbox" :value="column.name" :disabled="column.checked" v-model="selectedColumns"
                      @change="handleColumnChange(column.name)" />
                    {{ column.label }}
                  </label>
                </a>
              </li>
            </ul>
            <!--Status Dropdown-->
            <div v-if="tableProps.type == 'users'">
              <button type="button" class="btn btn-primary btn-sm filterButton dropdown-toggle" data-bs-toggle="dropdown"
                aria-expanded="false">
                Status
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    <label>
                      <input type="checkbox" :value="'*'" v-model="stateFilter" @change="handleStateChange('*')" />
                      Select All
                    </label>
                  </a>
                </li>
                <hr class="dropdown-divider" />
                <li v-for="(status, name) in states" :key="name">
                  <a class="dropdown-item" href="#">
                    <label>
                      <input type="checkbox" :value="status.name" v-model="stateFilter"
                        @change="handleStateChange(status.name)" />
                      {{ status.label }}
                    </label>
                  </a>
                </li>
              </ul>
            </div>
            <div v-else>
              <button type="button" class="btn btn-primary btn-sm filterButton dropdown-toggle" data-bs-toggle="dropdown"
                aria-expanded="false">
                Status
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    <label>
                      <input type="checkbox" :value="'*'" v-model="selectedFilter" @change="handleStatusChange('*')" />
                      Select All
                    </label>
                  </a>
                </li>
                <hr class="dropdown-divider" />
                <li v-for="(status, name) in this.$statuses" :key="name">
                  <a class="dropdown-item" href="#">
                    <label>
                      <input type="checkbox" :value="status.name" v-model="selectedFilter"
                        @change="handleStatusChange(status.name)" />
                      {{ status.label }}
                    </label>
                  </a>
                </li>
              </ul>
            </div>
            <!--Floating action TOolbar starts-->
            <div v-if="tableProps.actions" class="dropdown">
              <button v-if="selectedReqsForBulkOps.length > 0" class="btn btn-primary filterButton" type="button"
                id="dropdownActionsButton" data-bs-toggle="dropdown" aria-expanded="false"><img
                  src="../assets/icons/menu.svg" /></button>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownActionsButton">
                <li>
                  <div style="text-align: center;">{{ selectedReqsForBulkOps.length }} item(s) selected</div>
                </li>
                <hr class="dropdown-divider" />
                <li>
                  <div class="icon-row">
                    <div v-if="tableProps.actions.download" class="icon">
                      <button type="button" class="btn-download" @click="bulkDownload(selectedReqsForBulkOps)"
                        v-tooltip="tableProps.actions.download.label"></button>
                    </div>
                    <div v-if="tableProps.actions.delete" class="icon">
                      <button type="button" class="btn-delete" @click="bulkDelete(selectedReqsForBulkOps)"
                        v-tooltip="tableProps.actions.delete.label"></button>
                    </div>
                    <!-- <div v-if="tableProps.actions.restore" class="icon">
                      <button type="button" class="btn-restore" @click=""
                        v-tooltip="tableProps.actions.restore.label"></button>
                    </div> -->
                  </div>
                </li>
              </ul>
            </div>
            <!--Floating action TOolbar ends-->
          </div>
          <div class="col d-flex gap-2 justify-content-end right-margin">
            <button class="btn btn-primary btn-sm filterButton dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <span>{{ searchConcept }}</span>
            </button>

            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item mt-1" href="#" @click="setSearchFilter('All Categories')">All Categories</a>
              </li>
              <hr class="dropdown-divider" />
              <li v-for="(searchColumns, index) in columns.search" :key="index">
                <a class="dropdown-item mt-1" href="#" @click="setSearchFilter(searchColumns)">{{ searchColumns.label
                  }}</a>
              </li>

            </ul>
            <form class="search-form" @submit.prevent="performSearch">
              <input id="searchBar" class="form-control srchip" style="" v-model="searchQuery" @input="handleInputs"
                type="search" placeholder="" aria-label="Search" />
              <!-- <span v-if="searchQuery!=null" @click="clearSearch" class="clear-button">×</span> -->
              <input class="btn btn-outline-dark" type="submit" value="Search" @click="searchWithQuery()" />
            </form>
            <button class="clear-filter-icon" @click="clearFilters">
              <img src="../assets/icons/clear-filter-icon.svg" alt="filter-icon" class="filter-icon" />
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="filter-icon" height="25px" viewBox="0 -960 960 960" width="25px" fill="#1d2856"><path d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z"/></svg> -->
            </button>
          </div>
        </div>
        <!--Revamp panel ends-->
        <div v-if="loading" class="loading-container">
          <img id="loading-image" class="loading-gif" src="../assets/ajax_loader.gif" alt="Loading..." />
        </div>
        <div v-else class="table-responsive">
          <div>
            <table class="table table-hover table-borderless custom-table">
              <thead>
                <tr class="p-0">
                  <!--Table header to show menu icon in the header starts-->
                  <!-- <th v-if="isMultiSelectionAllowed" class="col align-middle table-header-sticky actions-dropdown-toggle">
                  </th> -->
                  <!--Table header to show menu icon in the header ends-->
                  <!--Table header to show selectAll checkbox in the header starts-->
                  <th v-if="isMultiSelectionAllowed" class="col align-middle table-header-sticky">
                    <input type="checkbox" v-model="selectAllCheckboxes" @change="toggleSelectAllCheckboxes" />
                  </th>
                  <!--Table header to show selectAll checkbox in the header ends-->
                  <th v-for="(header, index) in selectedConfigs" :key="index" scope="col"
                    class="col align-middle table-header-sticky" @mouseover="hoveredColumn = index"
                    @mouseleave="hoveredColumn = ''" @click="sort(header.name, index)">
                    <div class="pe-4">
                      {{ header.label }}
                    </div>
                    <div v-if="header.sort && hoveredColumn === index || sortColumn === index" class="sort-buttons">
                      <button class="svg-button" v-if="isDescending">
                        <svg class="svg-icon" style="
                            width: 1em;
                            height: 1em;
                            vertical-align: middle;
                            fill: currentColor;
                            overflow: hidden;
                          " viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M573.056 752l308.8-404.608A76.8 76.8 0 0 0 820.736 224H203.232a76.8 76.8 0 0 0-61.056 123.392l308.8 404.608a76.8 76.8 0 0 0 122.08 0z" />
                        </svg>
                      </button>
                      <button class="svg-button" v-if="!isDescending">
                        <svg class="svg-icon" style="
                            width: 1em;
                            height: 1em;
                            vertical-align: middle;
                            fill: currentColor;
                            overflow: hidden;
                          " viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M573.056 272l308.8 404.608A76.8 76.8 0 0 1 820.736 800H203.232a76.8 76.8 0 0 1-61.056-123.392L450.976 272a76.8 76.8 0 0 1 122.08 0z" />
                        </svg>
                      </button>
                    </div>
                  </th>

                  <th v-if="tableProps.actions" scope="col"
                    class="col align-middle cus-table-header-sticky flex-column-small">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr v-if="noRecordFound">
                  <td class="h4" colspan="15">No records found !</td>
                </tr>
                <tr v-else v-for="transaction in reportTransactions.transactions" :key="getUniqueIDValue(transaction)">
                  <td v-if="isMultiSelectionAllowed">
                    <input type="checkbox" :checked="selectedReqsForBulkOps.includes(getUniqueIDValue(transaction))"
                      @change=collateRequestIDs(getUniqueIDValue(transaction)) />
                  </td>
                  <td v-for="column in selectedConfigs" :key="column.name"
                    :style="getTextAlignmentStyle(transaction, column)">
                    <!-- {{ transaction.requestId }} -->
                    <template v-if="column.name === 'accelatorStatus'">
                      <div>
                        <span v-for="status in getStatusColumns(transaction)" :key="status.accuracy" class="status-container" v-tooltip="`${status.name} (${status.accuracyValue}%)`">
                          <svg class="progress-circle" viewBox="0 0 36 36">
                            <path class="circle-bg" d="M18 2.0845
                                     a 15.9155 15.9155 0 0 1 0 31.831
                                     a 15.9155 15.9155 0 0 1 0 -31.831" />
                            <path class="circle" :class="status.cssClass" :stroke-dasharray="status.strokeDasharray" d="M18 2.0845
                                     a 15.9155 15.9155 0 0 1 0 31.831
                                     a 15.9155 15.9155 0 0 1 0 -31.831" />
                          </svg>
                          <img :src="status.icon" alt="status" :class="['status-icon', 
                                        {
                                           'xslticon': status.icon.includes('xslticon.png'), 
                                          'kafka-icon': status.icon.includes('kafka-logo.png'), 
                                          'connector-icon': status.icon.includes('Connector.png'), 
                                          'not-applicable': status.statusValue === 'Not Applicable',
                                          'failed': status.statusValue === 'Failed',
                                          'in-progress': status.statusValue === 'In Progress'
                                        }]" 
                               :style="status.icon.includes('xslt') || status.icon.includes('kafka') || status.icon.includes('Connector') ? 'width: 30px; height: 30px;' : 'width: 25px; height: 25px;'" 
                               @click="!['Not Applicable'].includes(status.statusValue) && handleActionableColumns(transaction, status)" />
                        </span>
                      </div>
                    </template>
                    <template v-else-if="checkIfActionable(transaction, column)">
                      <a v-tooltip="computeAccuracy(transaction, column)"
                        :class="getStylesForStatus(transaction, column, isActionable = true)"
                        @click="handleActionableColumns(transaction, column)">{{ getTableData(transaction, column) }}
                        <!-- <span v-if="transaction.schemaAccuracy" class="accuracy">{{ transaction.schemaAccuracy }}</span> -->
                      </a>
                    </template>
                    <template v-else-if="checkIfDateColumn(column)">
                      <div v-tooltip="computeDateAndTimestamp(transaction, column)">{{ getTableData(transaction, column)
                        }}</div>
                    </template>
                    <template v-else-if="checkIfEditable(column)">
                      <div :class="[getStylesForStatus(transaction, column, isActionable = false), 'editable-icon']">
                        <select v-if="isEditing(getUniqueIDValue(transaction), column)" v-model="transaction[column.name]"
                          @change="trackChanges(transaction, column)">
                          <option v-for="option in column.dropdownOptions"
                            :key="resolveDropDownSelectionKey(option, column)"
                            :value="resolveDropDownSelectionKey(option, column)">{{ option }}
                          </option>
                          <div class="select-arrow">&#9660;</div>
                        </select>
                        <div v-else @click="startEditing(transaction, column)">{{ getTableData(transaction, column) }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div v-tooltip="computeAccuracy(transaction, column)"
                        :style="getTextOverflowStyle(transaction, column)"
                        :class="getStylesForStatus(transaction, column, isActionable = false)"
                        @mouseenter="handleMouseEnterForEllipsedText($event, transaction, column)"
                        @mouseleave="hidleEllipsedTextTooltip($event)">{{
                          getTableData(transaction,
                            column) }}
                        <!-- <span v-if="transaction.schemaAccuracy" class="accuracy">{{ transaction.schemaAccuracy }}</span> -->
                      </div>
                    </template>

                  </td>

                  <td v-if="tableProps.actions" class="sticky">
                    <div class="custom-row row nowrap">
                      <div class="col d-flex flex-column align-items-center sticky-column" v-if="tableProps.actions.view">
                        <button type="button" class="btn btn-lg btn-view" @click="
                          viewYAMLFile(
                            setActionData(
                              reportTransactions.userId,
                              transaction
                            )
                          );

                        " :disabled="isViewToBeDisabled(transaction)"
                          v-tooltip="tableProps.actions.view.label"></button>
                      </div>
                      <div class="col d-flex flex-column align-items-center sticky-column"
                        v-if="tableProps.actions.viewPopup">
                        <button type="button" class="btn btn-lg btn-view" @click="toggleTabs"
                          :disabled="isViewMapperToBeDisabled(transaction)"
                          v-tooltip="tableProps.actions.viewPopup.label"></button>
                      </div>

                      <div class="col d-flex flex-column align-items-center sticky-column"
                        v-if="tableProps.actions.download">
                        <button type="button" class="btn-download" @click="
                          download(
                            setActionData(
                              reportTransactions.userId,
                              transaction
                            )
                          )
                          " :disabled="isDownloadToBeDisabled(transaction)"
                          v-tooltip="tableProps.actions.download.label"></button>
                      </div>
                      <div class="col d-flex flex-column align-items-center" v-if="tableProps.actions.diff">
                        <button type="button" class="btn-show-difference" @click="
                          showDifference(tableProps.type,
                            setActionData(
                              reportTransactions.userId,
                              transaction
                            )
                          )
                          " :disabled="transaction.status != 'ok'" v-tooltip="tableProps.actions.diff.label"></button>
                        <!-- <span>{{ tableProps.actions.diff.label }}</span> -->
                        <!-- <p :class="{ 'grayed-out':  transaction.status != 'ok' }" class="small-font">
                          {{ tableProps.actions.diff.label }}
                        </p> -->
                      </div>
                      <div class="col d-flex flex-column align-items-center" v-if="tableProps.actions.transform">
                        <button type="button" class="btn-transform" @click="
                          xmlTransform(tableProps.type,
                            setActionData(
                              reportTransactions.userId,
                              transaction
                            )
                          )
                          " :disabled="isXMLTransformToBeDisabled(transaction)"
                          v-tooltip="tableProps.actions.transform.label"></button>

                        <!-- <span>{{ tableProps.actions.transform.label }}</span> -->
                        <!--  <p :class="{ 'grayed-out':  transaction.status != 'ok' }" class="small-font">
                          {{ tableProps.actions.transform.label }}
                        </p> -->
                      </div>
                      <div class="col d-flex flex-column align-items-center" v-if="tableProps.actions.delete">
                        <button type="button" class="btn-delete" @click="
                          /* confirmDelete(
                            reportTransactions.userId,
                            transaction.projectId?transaction.projectId:transaction.requestId.toString()
                          ) */
                          confirmDelete(
                            setActionData(
                              reportTransactions.userId,
                              transaction
                            )
                          )
                          " :disabled="[].includes(transaction.status)"
                          v-tooltip="tableProps.actions.delete.label"></button>
                        <!-- <span>{{tableProps.actions.delete.label}}</span> -->
                        <!-- <p :class="{ 'grayed-out': ['in-prog'].includes(transaction.status) }" class="small-font">
                          {{ tableProps.actions.delete.label }}
                        </p> -->
                      </div>
                      <div class="col d-flex flex-column align-items-center" v-if="tableProps.actions.restore">
                        <button type="button" class="btn-restore" @click="
                          restore(
                            setActionData(
                              reportTransactions.userId,
                              transaction
                            )
                          )
                          " :disabled="!['new', 'fail', 'ok', 'in-prog', 'NA'].includes(transaction.status)"
                          v-tooltip="tableProps.actions.restore.label"></button>
                        <!-- <p :class="{ 'grayed-out': !['new','fail','ok'].includes(transaction.status) }" class="small-font">
                          {{ tableProps.actions.restore.label }}
                        </p> -->
                        <!-- <span>Restore</span> -->
                      </div>
                      <div class="col d-flex flex-column align-items-center sticky-column"
                        v-if="tableProps.actions.calculate">
                        <button type="button" class="btn-calculate" @click="
                          calculateComplexity(
                            setActionData(
                              reportTransactions.userId,
                              transaction
                            )
                          )
                          " :disabled="isCalculateToBeDisabled(transaction)"
                          v-tooltip="tableProps.actions.calculate.label"></button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--PopUp Contents starts-->
          <div v-if="showPopup">
            <PopupYAML :viewContent="viewContent" :setShowPop="setShowPop" :title="viewTitle" />
          </div>
          <div v-if="showCalcPopup">
            <CalculateComplexityPopup :userId="userId" :type="type" :requestId="mapperParamId" :title="viewTitle"
              :project-param-id="projectParamId" @close="showCalcPopup = false" @reloadData="search"
              @openModal="openModalFromCalc" ref="removeRowRef" />
          </div>
          <!--PopUp Contents ends-->
        </div>
        <!--Pagination starts-->
        <div class="pagination-bar" v-if="!noRecordFound">
          <Pagination :totalPages="totalPages" :perPage="pageSize" :currentPage="currentPage"
            :totalRecords="totalRecords" @pagechanged="onPageChange" @recordCount="onRecordChange" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import Store from "../vuex/store.js";
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { redirect } from "@/utils/routerUtils.js";
import { changeFileExtension } from "@/utils/util.js";
import {
  action,
  elementsToRemoveFromArray,
  invalidBEColumns,
  supportingColumns,
  CONVERSION_STATUS,
  STATUS_COLUMNS,
  DATE_COLUMNS,
  ACCURACY_COLUMNS_STATUS_MAP,
  pageViews,
  type,
  user,
  api_mode,
  GRID_ACTION_DATA_ELEMENTS,
  ROUTER_PARAMS_GRID_FILTER_MAP,
  ROUTER_PARAMS_VIEW_MAP,
  role,
  EDITABLE_GRID_CHANGES_COLUMNS,
  EDITABLE_GRID_ACTIONS,
  COMPLEXITY_STATUS,
} from "../utils/util.js";
import { evaluate } from "mathjs";
import ConfirmModal from "./ConfirmModal.vue";
import { getCookie } from "../store/cookie.js";
import { useUploadStore } from "../store/fileUpload.js";
import { makeOpearationRequest } from "@/services/apiServices";
import { getSessionItem, setSessionItem, removeSessionItem } from "../store/session.js";
import { mock_response } from "../utils/mock.js";
import PopupYAML from "./PopupYAML.vue";
import Pagination from './Pagination.vue';
import CalculateComplexityPopup from "./CalculateComplexityPopup.vue";

export default {
  name: "ReportTemplate",
  props: ["tableProps", "projectParamId", "xslParamId", "xmlParamId", "jmsParamId", "xsdParamId", "configParamId", "mapperParamId"],
  emits: ["editableGridUpdated", 'toggleTabs'],
  data() {
    return {
      viewContent: [],
      viewTitle: "",
      showPopup: false,
      dateDelimiter: "/",
      sortColumn: this.getDefaultSortColumnIndex(),
      hoveredColumn: "",
      isDescending: true,
      noRecordFound: false,
      searchName: "",
      searchConcept: "All Categories",
      searchQuery: "",
      tableConfigs: {},
      selectedConfigs: {},
      showCalcPopup: false,
      columns: {
        default: {},
        sort: {},
        search: {}
      },
      files: [],
      filterSortColumn: {
        name: (this.tableProps.defaultSortColumn && this.tableProps.defaultSortColumn.name) ? this.tableProps.defaultSortColumn.name : "requestDate",
        label: (this.tableProps.defaultSortColumn && this.tableProps.defaultSortColumn.label) ? this.tableProps.defaultSortColumn.label : "Request Date",
      },
      filterSortOrder: {
        name: (this.tableProps.defaultSortOrder && this.tableProps.defaultSortOrder.name) ? this.tableProps.defaultSortOrder.name : "desc",
        label: (this.tableProps.defaultSortOrder && this.tableProps.defaultSortOrder.label) ? this.tableProps.defaultSortOrder.label : "Descending",
      },
      reportTransactions: "",
      sortOrders: [
        { name: "asc", label: "Ascending" },
        { name: "desc", label: "Descending" },
      ],
      userId: getCookie(user.id),
      uploadSuccessMessage: "",
      uploadErrorMessage: "",
      loading: false,
      selectedFilter: ["ok", "new", "fail", "in-prog", "NA", "*"],
      stateFilter: ["true", "false", "*"],
      refreshReport: {
        interval: 5000,
        autoRefresh: false,
        isAutoRefresh: false,
      },
      selectedColumns: [],
      popup: {},
      selectedReqsForBulkOps: [],
      dateAndTimestamp: "",
      currentPage: 1,
      pageSize: 20,
      totalPages: 5, //Just initial value. will be overridden in search() api call
      isMultiSelectionAllowed: false,
      uploadStore: useUploadStore(),
      ellipsesdTextoolTip: null,
      selectAllCheckboxes: false,
      changes: [],
      editingId: null,
      originalValues: {},
      refinedEditableGridViewColumns: [],
      refinedEditableGridUpdatesToBeEmitted: {},
      totalRecords: 0,
      states: [{ "name": "true", "label": "In-Active" }, { "name": "false", "label": "Active" }],
      isMenuVisible: false,
      isGridVisible: true,
    };
  },
  components: {
    ConfirmModal,
    PopupYAML,
    Pagination,
    CalculateComplexityPopup,
  },

  computed: {
    routerParams() {
      return this.$route.params;
    },
    sorByDropdown() {
      return this.filterSortColumn.label || "Sort By";
    },
    orderDropdown() {
      return this.filterSortOrder.label || "Order";
    },
    requestId() {
      // return Store.getters.getRequestId;
      return getSessionItem("requestId");
    },
    computeDateAndTimestamp() {
      return (transaction, column) => {
        return this.convertToLocalTimeAndFormat(transaction[column.name], true);
      }
    },
    computeAccuracy() {
      return (transaction, column) => {
        return this.checkReturnAccuracyValue(transaction, column);
      }
    },
    uploadComplete() {
      return this.uploadStore.uploadComplete;
    },
    refinedChanges() {
      const result = this.changes.map(change => {
        const processedObject = {};
        Object.keys(this.refinedEditableGridViewColumns).forEach(key => {
          const columnMetadata = this.tableConfigs.filter((item) => item.name === key).reduce((acc, curr) => { return curr });
          if (key in change) {
            processedObject[this.refinedEditableGridViewColumns[key]] = (STATUS_COLUMNS.includes(key) && change.hasOwnProperty(key)) ? CONVERSION_STATUS.getByValue(change[key]).name : change[key];
          }
          if (columnMetadata && columnMetadata.traceable) {
            const currentKey = `current_${key}`;
            if (currentKey in change) {
              processedObject[`Prior ${this.refinedEditableGridViewColumns[key]}`] = (STATUS_COLUMNS.includes(key) && change.hasOwnProperty(key)) ? CONVERSION_STATUS.getByValue(change[currentKey]).name : change[currentKey];
            } else {
              processedObject[`Prior ${this.refinedEditableGridViewColumns[key]}`] = (STATUS_COLUMNS.includes(key) && change.hasOwnProperty(key)) ? CONVERSION_STATUS.getByValue(change[key]).name : change[key];
            }
          }
        });
        return processedObject;
      });
      return result;
    },
  },
  async mounted() {
    this.getSetConfig();
    this.refresh();
    let isViewUpdated = getSessionItem(this.tableProps.type + '_view_updated');
    isViewUpdated ? this.copyPropsFromSession() : this.copyProps();
    this.search();
  },
  unmounted() {
    clearInterval(this.refreshReport.isAutoRefresh);
  },
  watch: {
    uploadComplete(uploadStats) {
      if (uploadStats) {
        this.refresh();
        this.search();
        this.uploadStore.setUploadComplete(false);
      }
    },
  },
  methods: {
    toggleTabs() {
      this.$emit('toggleTabs');
    },
    getDefaultSortColumnIndex() {
      const userRole = getCookie(user.role);
      const defaultSortColumn = this.tableProps.defaultSortColumn;
      if (!defaultSortColumn || !defaultSortColumn.name) {
        return ([role.admin, role.superadmin].includes(userRole) && this.tableProps.type != type.USER_ROLES) ? 1 : 0;
      }
      const sortColumnName = defaultSortColumn.name;
      const config = this.tableProps.configs.find(item => item.name === sortColumnName);
      let colIndex = config ? (config.order > 0 ? config.order - 1 : config.order) : 0;
      if ([role.admin, role.superadmin].includes(userRole) && this.tableProps.type != type.USER_ROLES) {
        return colIndex + 1;
      }
      return colIndex;
    },
    setShowPop(value) {
      this.showPopup = value;
    },
    collateRequestIDs(requestId) {
      const index = this.selectedReqsForBulkOps.indexOf(requestId);
      index > -1 ? this.selectedReqsForBulkOps.splice(index, 1) : this.selectedReqsForBulkOps.push(requestId);
      this.updateSelectAllCheckboxes();
    },
    updateSelectAllCheckboxes() {
      this.selectAllCheckboxes = this.selectedReqsForBulkOps.length === this.reportTransactions.transactions.length;
    },
    toggleSelectAllCheckboxes() {
      if (this.selectAllCheckboxes) {

        this.selectedReqsForBulkOps = this.reportTransactions.transactions.map(txn => this.getUniqueIDValue(txn));
      } else {
        this.selectedReqsForBulkOps = [];
      }
    },
    setType(type) {
      setSessionItem("type", type);
    },
    checkReturnAccuracyValue(transaction, column) {
      let tooltip_suffix = "% accurate"
      if (STATUS_COLUMNS.includes(column.name) && transaction.hasOwnProperty(ACCURACY_COLUMNS_STATUS_MAP[column.name])) {
        return transaction[ACCURACY_COLUMNS_STATUS_MAP[column.name]] + tooltip_suffix;
      }
    },
    checkIfDateColumn(column) {
      if (DATE_COLUMNS.includes(column.name))
        return true;
      return false;
    },
    checkIfEditable(column) {
      return (column.isEditable && column.dropdownOptions);
    },
    checkIfActionable(transaction, column) {
      const actionableStatus = [CONVERSION_STATUS.OK.value];
      /* const actionableStatus = [CONVERSION_STATUS.OK.value, CONVERSION_STATUS.PROGRESS.value, CONVERSION_STATUS.NA.value, CONVERSION_STATUS.FAIL.value]; */
      if (column.actionable && column.routeTo)
        if (STATUS_COLUMNS.includes(column.name) && transaction.hasOwnProperty(column.name) && actionableStatus.includes(transaction[column.name]))
          return true;
      return false;
    },
    getStylesForStatus(transaction, column, isActionable) {
      const allStatus = [CONVERSION_STATUS.OK.value, CONVERSION_STATUS.PROGRESS.value, CONVERSION_STATUS.NA.value, CONVERSION_STATUS.FAIL.value, CONVERSION_STATUS.NEW.value, CONVERSION_STATUS.TRUE.value, CONVERSION_STATUS.FALSE.value];
      if (STATUS_COLUMNS.includes(column.name) && transaction.hasOwnProperty(column.name) && allStatus.includes((transaction[column.name].toString()))) {
        if (CONVERSION_STATUS.getByValue(transaction[column.name]) == CONVERSION_STATUS.OK) {
          return isActionable ? "text-success actionable" : "text-success";
        } else if (CONVERSION_STATUS.getByValue(transaction[column.name]) == CONVERSION_STATUS.PROGRESS) {
          return isActionable ? "text-in-progress actionable blink" : "text-in-progress blink";
        } else if (CONVERSION_STATUS.getByValue(transaction[column.name]) == CONVERSION_STATUS.NA) {
          return isActionable ? "text-not-applicable actionable" : "text-not-applicable";
        } else if (CONVERSION_STATUS.getByValue(transaction[column.name]) == CONVERSION_STATUS.FAIL) {
          return isActionable ? "text-danger actionable" : "text-danger";
        } else if (CONVERSION_STATUS.getByValue(transaction[column.name]) == CONVERSION_STATUS.NEW) {
          return isActionable ? "text-new-status actionable" : "text-new-status";
        } else if (CONVERSION_STATUS.getByValue(transaction[column.name]) == CONVERSION_STATUS.TRUE) {
          return isActionable ? "text-danger actionable" : "text-danger";
        } else if (CONVERSION_STATUS.getByValue(transaction[column.name]) == CONVERSION_STATUS.FALSE) {
          return isActionable ? "text-success actionable" : "text-success";
        }
      }
      return;
    },
    getTextAlignmentStyle(transaction, column) {
      if (column.textAlign && column.textOverflowStyle) {
        return `text-align:${column.textAlign};white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 250px;`;
      } else if (column.textAlign) {
        return `text-align:${column.textAlign}`;
      } else if (column.textOverflowStyle) {
        return `white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 250px;`;
      }
      return;
    },
    getTextOverflowStyle(transaction, column) {
      return column.textOverflowStyle ? `white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%; display: inline-block` : "";
    },
    handleMouseEnterForEllipsedText(event, transaction, column) {
      const target = event.target;
      if (column.textOverflowStyle && (target.scrollWidth > target.clientWidth)) {
        const textContent = target.innerText;
        const rect = target.getBoundingClientRect();
        this.ellipsesdTextoolTip = document.createElement('div');
        this.ellipsesdTextoolTip.className = 'custom-ellipsed-text-tooltip';
        this.ellipsesdTextoolTip.innerText = textContent;
        document.body.appendChild(this.ellipsesdTextoolTip);
        this.ellipsesdTextoolTip.style.top = `${rect.top + window.scrollY - this.ellipsesdTextoolTip.offsetHeight - 5}px`;
        this.ellipsesdTextoolTip.style.left = `${rect.left + window.scrollX}px`;
      }
    },
    hidleEllipsedTextTooltip(event) {
      if (this.ellipsesdTextoolTip) {
        document.body.removeChild(this.ellipsesdTextoolTip);
        this.ellipsesdTextoolTip = null;
      }
    },
    checkToShowTrash(viewName) {
      if (viewName == pageViews.ALLINONE_ACC_VIEW)
        return true;
      return false;
    },
    handleActionableColumns(transaction, column) {
      this.redirectView(column.routeTo, transaction);
    },
    isDownloadToBeDisabled(transaction) {
      const matchingStatusColumns = STATUS_COLUMNS.filter(key => transaction.hasOwnProperty(key));
      let statusToBeChecked = [];
      if ([type.KAFKA, type.PROJECT].includes(this.tableProps.type)) {
        statusToBeChecked = [CONVERSION_STATUS.OK.value];
      } else {
        statusToBeChecked = [CONVERSION_STATUS.OK.value, CONVERSION_STATUS.NA.value, CONVERSION_STATUS.FAIL.value, CONVERSION_STATUS.PROGRESS.value];
      }
      const atleastOnePassed = matchingStatusColumns.map(key => transaction[key]).some(element => statusToBeChecked.includes(element));
      return !atleastOnePassed;
    },
    isViewToBeDisabled(transaction) {
      const matchingStatusColumns = STATUS_COLUMNS.filter(key => transaction.hasOwnProperty(key));
      let statusToBeChecked = [];
      if ([type.KAFKA].includes(this.tableProps.type)) {
        statusToBeChecked = [CONVERSION_STATUS.OK.value];
      } else {
        statusToBeChecked = [CONVERSION_STATUS.OK.value, CONVERSION_STATUS.NA.value, CONVERSION_STATUS.FAIL.value, CONVERSION_STATUS.PROGRESS.value];
      }
      const atleastOnePassed = matchingStatusColumns.map(key => transaction[key]).some(element => statusToBeChecked.includes(element));
      return !atleastOnePassed;
    },
    isViewMapperToBeDisabled(transaction) {
      const matchingStatusColumns = STATUS_COLUMNS.filter(key => transaction.hasOwnProperty(key));
      let statusToBeChecked = [];
      if ([type.MAPPER].includes(this.tableProps.type)) {
        statusToBeChecked = [CONVERSION_STATUS.OK.value];
      } else {
        statusToBeChecked = [CONVERSION_STATUS.OK.value, CONVERSION_STATUS.NA.value, CONVERSION_STATUS.FAIL.value, CONVERSION_STATUS.PROGRESS.value];
      }
      const atleastOnePassed = matchingStatusColumns.map(key => transaction[key]).some(element => statusToBeChecked.includes(element));
      return !atleastOnePassed;
    },
    isXMLTransformToBeDisabled(transaction) {
      const matchingStatusColumns = STATUS_COLUMNS.filter(key => transaction.hasOwnProperty(key));
      let statusToBeChecked = [CONVERSION_STATUS.OK.value, CONVERSION_STATUS.NA.value, CONVERSION_STATUS.FAIL.value, CONVERSION_STATUS.PROGRESS.value];
      const atleastOnePassed = matchingStatusColumns.map(key => transaction[key]).some(element => statusToBeChecked.includes(element));
      return !atleastOnePassed;
    },
    handleInputs(event) {
      if (!event.target.value) {
        this.search();
      }
    },

    getSetConfig() {
      //const config = require("../../public/config.json");
      this.refreshReport.autoRefresh = window.acclConfigs.AUTO_REFRESH;
      this.refreshReport.interval = window.acclConfigs.REFRESH_INTERVAL;
    },
    sort(name, index) {
      if (this.isDescending) {
        this.sortAscending(name, index);
      } else {
        this.sortDescending(name, index);
      }
    },
    clearSort() {
      this.sortColumn = this.getDefaultSortColumnIndex();
      this.filterSortColumn = {
        name: (this.tableProps.defaultSortColumn && this.tableProps.defaultSortColumn.name) ? this.tableProps.defaultSortColumn.name : "requestDate",
        label: (this.tableProps.defaultSortColumn && this.tableProps.defaultSortColumn.label) ? this.tableProps.defaultSortColumn.label : "Request Date",
      };
      this.filterSortOrder = {
        name: (this.tableProps.defaultSortOrder && this.tableProps.defaultSortOrder.name) ? this.tableProps.defaultSortOrder.name : "desc",
        label: (this.tableProps.defaultSortOrder && this.tableProps.defaultSortOrder.label) ? this.tableProps.defaultSortOrder.label : "Descending"
      };
    },
    sortAscending(name, index) {
      this.sortColumn = index;
      this.isDescending = false;
      this.updateFilterSortOrder("asc", "Ascending");
      this.handleSortByChange(name);
    },

    sortDescending(name, index) {
      this.sortColumn = index;
      this.isDescending = true;
      this.updateFilterSortOrder("desc", "Descending");
      this.handleSortByChange(name);
    },
    clearFilters() {
      this.selectedFilter = ["*", "ok", "new", "fail", "in-prog", "NA"];
      this.copyProps();
      // this.filterSortColumn = { name: "lastModifiedDate", label: "Last Modified Date" };
      // this.filterSortOrder = { name: "desc", label: "Descending" };
      this.searchName = "";
      this.searchConcept = "All Categories";
      this.searchQuery = "";
      this.clearSort();
      this.clearUpdatedViews();
      this.search();
    },
    clearUpdatedViews() {
      removeSessionItem(this.tableProps.type + '_columns');
      removeSessionItem(this.tableProps.type + '_view_updated');
    },
    setSearchFilter(filter) {
      if (filter == "All Categories") {
        this.searchConcept = filter;
      } else {
        this.searchConcept = filter.label;
      }
      this.searchName = filter.name;
    },
    openModalFromCalc(data) {
      this.popup = data
      this.openModal()
    },
    openModal() {
      this.$refs.openModalBox.openModal();
    },
    evaluateFormula(formula, row) {
      try {
        let result = evaluate(formula.expression, row);
        if (formula.roundTo) {
          result = this.roundTo(result, formula.roundTo);
        }
        return result;
      } catch (error) {
        console.error(`Error evaluating formula: ${formula}`, error);
        return "Error";
      }
    },
    roundTo(num, precision) {
      const factor = Math.pow(10, precision);
      return Math.round(num * factor) / factor;
    },
    resolveDropDownSelectionKey(selectedValue, cloumn) {
      if (STATUS_COLUMNS.includes(cloumn.name)) {
        let dropdownKey = CONVERSION_STATUS.getByName(selectedValue);
        return dropdownKey.value;
      } else {
        return selectedValue;
      }
    },
    getTableData(record, column) {
      if (column.name === "accelatorStatus") {
        return this.getStatusColumns(record).map(({ icon, statusValue, accuracyValue }) => {
          return `<img src="${icon}" alt="status" style="width: 25px; height: 25px;" /> - ${statusValue} (${accuracyValue}%)`;
        }).join(", ");
      }
      if (column.name === "complexity") {
        try {
          return COMPLEXITY_STATUS.getByValue(record[column.name]).name;
        } catch (error) {
          return '-';
        }
      }
      let value = record[column.name];
      if (STATUS_COLUMNS.includes(column.name) && record.hasOwnProperty(column.name)) {
        let conv_status = CONVERSION_STATUS.getByValue(record[column.name]);
        value = conv_status.name;
      }

      if (column.type == "date") {
        value = this.convertToLocalTimeAndFormat(value, false);
      } else if (column.formula) {
        if (record.status === "ok") {
          let compType = this.tableProps.type;
          if (type.XSL == compType && record.tibcoCount == 0) {
            value = 0;
          } else {
            value = this.evaluateFormula(column.formula, record);
          }

          if (column.suffix) {
            value = value + column.suffix;
          }
        } else {
          value = "-";
          // value= "0";
        }
      } else if (column.suffix && value != undefined) {
        value = value + column.suffix;
      } else if (value == undefined) {
        value = '-';
      }

      return value;
    },

    setValuesToStore(componentType, userId, requestId, convertedFileName) {
      setSessionItem("userId", userId);
      componentType === type.XML ? setSessionItem("xmlrequestId", requestId) : setSessionItem("requestId", requestId);
      setSessionItem("convertedFileName", convertedFileName);
      setSessionItem("componentType", componentType);
      if (componentType === type.XSL) {
        setSessionItem("xslFileName", convertedFileName);
      }
    },

    copyProps() {
      this.tableConfigs = [...this.tableProps.configs].sort(
        (a, b) => a.order - b.order
      );
      this.addUserColumnIfAdmin();
      this.selectedConfigs = this.tableConfigs.filter(
        (column) => column.default
      );
      /* this.columns.sort = this.tableConfigs
        .filter((item) => item.sort)
        .map(({ name, label }) => ({ name, label })); */

      this.selectedColumns = this.selectedConfigs.map((column) => column.name);

      this.columns.search = this.tableConfigs
        .filter((item) => item.search != false)
        .map(({ name, label }) => ({ name, label }));

      this.isMultiSelectionAllowed = this.tableProps.isMultiSelectionAllowed;
    },

    copyPropsFromSession() {
      this.tableConfigs = [...this.tableProps.configs].sort(
        (a, b) => a.order - b.order
      );
      this.selectedConfigs = getSessionItem(this.tableProps.type + '_columns');
      /* this.columns.sort = this.tableConfigs
        .filter((item) => item.sort)
        .map(({ name, label }) => ({ name, label }));
 */
      this.selectedColumns = this.selectedConfigs.map((column) => column.name);

      this.columns.search = this.tableConfigs
        .filter((item) => item.search != false)
        .map(({ name, label }) => ({ name, label }));

      this.isMultiSelectionAllowed = this.tableProps.isMultiSelectionAllowed;
    },
    addUserColumnIfAdmin() {
      let userRole = getCookie(user.role)
      if ([role.admin, role.superadmin].includes(userRole) && this.tableProps.type != type.USER_ROLES) {
        let userObj = {
          name: "userId",
          label: "User ID",
          order: 0,
          sort: true,
          default: true,
          textAlign: "left",
        };
        this.tableConfigs.unshift(userObj)
      }
    },
    handleSortByChange(name, label) {
      this.filterSortColumn.name = name;
      this.filterSortColumn.label = label;
      this.search();
    },
    updateFilterSortOrder(name, label) {
      this.filterSortOrder.name = name;
      this.filterSortOrder.label = label;
    },
    convertToLocalTimeAndFormat(databaseDateString, appendTimeStamp) {
      const dateObject = new Date(databaseDateString);

      // Convert to local time
      const localDateObject = new Date(
        dateObject.getTime() - dateObject.getTimezoneOffset() * 60000
      );
      return this.formatDate(localDateObject, appendTimeStamp);
    },
    formatDate(dateTimeString, appendTimeStamp) {
      const dateObject = new Date(dateTimeString);
      let formattedDate = `${this.formatTwoDigits(
        dateObject.getMonth() + 1
      )}${this.dateDelimiter}${this.formatTwoDigits(
        dateObject.getDate()
      )}${this.dateDelimiter}${dateObject.getFullYear()}`;

      if (appendTimeStamp) {
        formattedDate = formattedDate + ` ${this.formatTwoDigits(
          dateObject.getHours()
        )}:${this.formatTwoDigits(
          dateObject.getMinutes()
        )}:${this.formatTwoDigits(dateObject.getSeconds())}`;
      }
      return formattedDate;
    },
    formatTwoDigits(value) {
      return value.toString().padStart(2, "0");
    },
    confirmDelete(incomingData) {
      let data = {
        userId: incomingData.userId,
        action: action.DELETE,
        type: incomingData.fileType,
        requestId: [incomingData.requestId]
      };
      this.setConfirmModalValues(
        "Delete Confirmation",
        "Are you sure want to delete ?",
        "Delete",
        "Cancel",
        data
      );
      this.openModal();
    },
    confirmDeleteAll() {
      let data = {
        userId: getCookie(user.id),
        action: action.DELETE,
        type: this.tableProps.type,
        requestId: this.selectedReqsForBulkOps
      };
      this.setConfirmModalValues(
        "Delete Confirmation",
        `Are you sure want to delete ${this.selectedReqsForBulkOps.length} File(s) ?`,
        "Delete",
        "Cancel",
        data
      );
      this.openModal();
    },
    setConfirmModalValues(
      title,
      body,
      actionLabel,
      secondaryActionLabel,
      data
    ) {
      const confirmModalData = {
        title,
        body,
        actionLabel,
        secondaryActionLabel,
        data,
      };
      this.popup = { ...confirmModalData };
      // this.$refs.openModalBox.setModalValues(confirmModalData);
    },

    handleColumnChange(value) {
      if (value === "*") {
        if (this.selectedColumns?.length === this.tableConfigs?.length) {
          //this.selectedColumns = [];
          this.selectedColumns = this.tableConfigs
            .filter((column) => column.checked)
            .map((item) => item.name);
        } else if (
          this.selectedColumns?.length <=
          this.tableConfigs?.length - 2
        ) {
          this.selectedColumns = this.tableConfigs.map(
            (column) => column?.name
          );
          this.selectedColumns.push("*");
        }
      }
      if (value !== "*") {
        this.selectedColumns = this.selectedColumns.filter(
          (selectedColumn) => selectedColumn !== "*"
        );
      }

      this.selectedValidColumns = this.selectedColumns.filter(
        (column) => column !== "*"
      );

      this.selectedConfigs = this.tableConfigs.filter((column) =>
        this.selectedColumns.includes(column.name)
      );
      setSessionItem(this.tableProps.type + '_columns', this.selectedConfigs);
      setSessionItem(this.tableProps.type + '_view_updated', true);
      this.search();
    },
    handleStateChange(value) {
      if (value === "*") {
        if (this.stateFilter.length === 0) {
          this.stateFilter.push("false");
        }

        if (this.stateFilter?.length === this.states?.length) {
          this.stateFilter = this.states
            .filter((item) => item.name == "false")
            .map((item) => item.name);
        } else {
          this.stateFilter = this.states?.map((status) => status.name);
          this.stateFilter.push("*");
        }
      }
      if (value !== "*") {
        this.stateFilter = this.stateFilter.filter(
          (statusFilter) => statusFilter !== "*"
        );
      }
      console.log("Selected Filters", this.stateFilter);
      this.search();
    },
    handleStatusChange(value) {
      if (value === "*") {
        if (this.selectedFilter.length === 0) {
          this.selectedFilter.push("ok");
        }

        if (this.selectedFilter?.length === this.$statuses?.length) {
          this.selectedFilter = this.$statuses
            .filter((item) => item.name == "ok")
            .map((item) => item.name);
        } else {
          this.selectedFilter = this.$statuses?.map((status) => status.name);
          this.selectedFilter.push("*");
        }
      }
      if (value !== "*") {
        this.selectedFilter = this.selectedFilter.filter(
          (statusFilter) => statusFilter !== "*"
        );
      }
      console.log("Selected Filters", this.selectedFilter);
      this.search();
    },
    hardShowLoading(message) {
      return toast.loading(message, {
        autoClose: false,
        closeOnClick: false,
      });
    },
    showSuccess(message) {
      return toast.success(message, {
        autoClose: 3000,
      });
    },
    showError(message) {
      toast.error(message, {
        autoClose: 10000,
      });
    },

    handlePrimaryAction(data) {

      if (data.action) {
        if (data.action === action.DELETE) {
          this.deleteRecord(data);
        } else if (data.action === action.REPLACE) {
          this.restoreRecord(data);
        } else if (data.action === action.REMOVE_CALC_ROW) {
          this.$refs.removeRowRef.removeRow(data);
        }
      }


    },
    async bulkDelete(reqIdArr) {
      let data = {
        userId: getCookie(user.id),
        type: this.tableProps.type,
        requestId: reqIdArr
      }
      this.deleteRecord(data);
    },
    async deleteRecord(data) {
      console.log("Deleting record");
      let body = {
        userId: data.userId,
        type: data.type,
        requestIds: data.requestId
      };

      try {
        const response = await makeOpearationRequest(this.$acclConfigs, "/delete", body);
        if (response.status === 200) {
          this.search();
          this.showSuccess(response.data.message);
        } else {
          this.showError(response.data.message);
        }
      } catch (error) {
        console.error("Delete failed:", error);
      } finally {
        this.selectedReqsForBulkOps = []
      }
    },
    async restore(incomingData) {
      // console.log("User ID is " + userId);
      // console.log("file type is " + type);
      // console.log("Request ID is " + requestId);
      let checkFileReqBody = {
        userId: incomingData.userId,
        fileName: incomingData.fileName
      }

      let response = null;
      try {
        // response = await axios.post(
        //   process.env.VUE_APP_BASE_XSL_URL + "/checkfile",
        //   body
        // );
        response = await makeOpearationRequest(this.$acclConfigs, "/checkfile", checkFileReqBody);
        console.log("Response :", response);

        if (response.status == 200) {
          this.availableFiles = incomingData.fileType == type.PROJECT ? [response.data] : response.data.availableFiles;
          // console.log("Available files in Trash View", this.availableFiles)
          const isDeleteFile = this.availableFiles[0].isDeleted;
          if (isDeleteFile === false) {
            let data = {
              userId: incomingData.userId,
              action: action.REPLACE,
              type: incomingData.fileType,
              requestId: [incomingData.requestId],
            };
            this.setConfirmModalValues(
              "Restore Confirmation",
              "The file already exists. Are you sure want to Restore ?",
              "Restore",
              "Cancel",
              data
            );
            this.openModal();
          } else if (isDeleteFile === true) {
            console.log("Restore Else if part is invoked");
            let data = {
              userId: incomingData.userId,
              type: incomingData.fileType,
              requestId: [incomingData.requestId],
            };
            this.restoreRecord(data);
          }
        } else if (response.status == 404) { // To handle the scenario where the file was successfully deleted
          console.log("Restore Else if part is invoked");
          let data = {
            userId: incomingData.userId,
            type: incomingData.fileType,
            requestId: [incomingData.requestId],
          };
          this.restoreRecord(data);
        }
      } catch (error) {
        if (error.response.status == 404) { // To handle the scenario where the file was successfully deleted
          let data = {
            userId: incomingData.userId,
            type: incomingData.fileType,
            requestId: [incomingData.requestId],
          };
          this.restoreRecord(data);
        } else {
          console.error('Error making api call', error);
        }
      }
    },

    async restoreRecord(data) {
      let body = {
        userId: data.userId,
        type: data.type,
        requestIds: data.requestId
      };
      try {
        // const response = await axios.post(
        //   process.env.VUE_APP_BASE_XSL_URL + "/restore",
        //   body
        // );
        const response = await makeOpearationRequest(this.$acclConfigs, "/restore", body);
        if (response.status === 200) {
          this.search();

          this.showSuccess(response.data.message);
        } else {
          this.showError(response.data.message);
        }
      } catch (error) {
        console.error("restore failed:", error);
      }
    },
    removeInvalidBEColumns(targetArray) {
      return targetArray.filter(
        (element) => !elementsToRemoveFromArray.includes(element)
      );
    },
    addUniqueElements(array, elements) {
      array.push(...elements);
      const uniqueElements = new Set(array);
      return Array.from(uniqueElements);
    },

    async searchWithQuery() {
      this.currentPage = 1;
      await this.search();
    },

    async search() {
      // console.log("Selected Colums in report ", this.selectedColumns);
      // if (this.searchQuery == "") {
      //   this.showError("Search Column is empty.Please type and search");
      // }
      //const projectId = getSessionItem("projectId");
      //const projectId = this.routerParams.projectParamId;
      let searchValues = { queryValue: this.searchQuery.trim() };

      if (this.searchName != "") {
        searchValues = { ...searchValues, ...{ queryColumn: this.searchName } };
      }

      let fields = [...this.selectedColumns];
      console.log("Table props below ");
      console.log(this.tableProps.filters);
      const filters = this.initializeDefaultFilters();
      // Construct the filters array with the specified structure

      /* if (projectId && ![type.PROJECT, type.XML].includes(this.tableProps.type)) {
        filters.push({
          projectId: projectId
        })
      } */
      /*  console.log("Table props below ",this.tableProps);
       if (this.tableProps.type == type.XML) {
         filters.push({
           xslId: this.requestId,
         });
       } */

      // Log the filters to check the structure
      //console.log(filters);


      fields = this.addUniqueElements(
        fields,
        supportingColumns[this.tableProps.type]
      );
      // var api = "/report";
      let invalidColumns = invalidBEColumns[this.tableProps.type];
      fields = fields.filter((column) => !invalidColumns.includes(column));

      /**Overriding the Filters based on the Path params from route - To handle deep links -- starts*/
      this.setFiltersFromRoute(filters);
      /**Overriding the Filters based on the Path params from route - To handle deep links -- ends*/

      let payload = {
        userId: this.userId,
        type: this.tableProps.type,
        fields: fields,
        filters: filters,
        sort: {
          field: this.filterSortColumn.name,
          order: this.filterSortOrder.name,
        },
        pagination: {
          page: this.currentPage,
          pageSize: this.pageSize,
        },
      };

      // console.log("Selected Colums in report after delete ", this.selectedColumns);
      if (searchValues != null) {
        payload = { ...payload, ...searchValues };
      }
      try {
        let response = {};
        if (api_mode == "actual") {
          response = await makeOpearationRequest(this.$acclConfigs, "/search", payload);
        } else {
          if (this.tableProps.type == type.SCHEMA) { response = mock_response.SCHMEA_SEARCH_API; }
          else if (this.tableProps.type == type.XSL) { response = mock_response.XSL_SEARCH_API; }
          else if (this.tableProps.type == type.XML) { response = mock_response.XML_SEARCH_API; }
          else if (this.tableProps.type == type.PROJECT) { response = mock_response.PROEJCT_SEARCH_API; }
          else if (this.tableProps.type == type.KAFKA) { response = mock_response.KAFKA_SEARCH_API; }
          else if (this.tableProps.type == type.USER_ROLES) { response = mock_response.USER_ROLES_SEARCH_API; }
          else if (this.tableProps.type == type.MAPPER) { response = mock_response.USER_ROLES_SEARCH_API; }
          else if (this.tableProps.type == type.API) { response = mock_response.API_SEARCH_API; }

        }
        if (response.status == 200 && !response.data.transactions.length) {
          this.noRecordFound = true;
          console.log("transaction empty");
        } else {
          this.noRecordFound = false;
          //console.log('Oops, something went wrong', response.status)
        }
        if (
          response.status == 200 &&
          this.refreshReport.isAutoRefresh == false &&
          this.refreshReport.autoRefresh == true
        ) {
          this.refreshReport.isAutoRefresh = true;
          console.log(this.response);
          this.refreshReport.isAutoRefresh = setInterval(() => {
            this.search();
          }, this.refreshReport.interval);
        }
        this.reportTransactions = response.data;
        this.totalRecords = response.data.totalRecords;
        this.totalPages = Math.ceil(response.data.totalRecords / this.pageSize);
      } catch (error) {
        if (error.response) {
          // The server responded with a status code outside the 2xx range
          console.log("Error response:", error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("Error request:", error.request);
        } else {
          // Something happened in setting up the request that triggered an error
          console.log("Error message:", error.message);
        }
      } finally {
        this.loading = false;
      }
      // })
      // .catch((error) => {
      //   if (error.response) {
      //     // The server responded with a status code outside the 2xx range
      //     console.log("Error response:", error.response);
      //   } else if (error.request) {
      //     // The request was made but no response was received
      //     console.log("Error request:", error.request);
      //   } else {
      //     // Something happened in setting up the request that triggered an error
      //     console.log("Error message:", error.message);
      //   }
      // })
      // .finally(() => {
      //   // Set loading state to false after request completes
      //   this.loading = false;
      // });
    },
    initializeDefaultFilters() {
      let filters = [];
      let isDeleteProps = false;
      if (Array.isArray(this.tableProps.filters)) {
        isDeleteProps = this.tableProps.filters.some(
          (filter) => filter.isDeleted === true
        );
      }
      if (this.tableProps.type === type.USER_ROLES) {
        filters = this.stateFilter.length >= this.states.length ? [] : this.removeInvalidBEColumns(this.stateFilter).map(item => ({ isDisabled: item }));
      } else {
        filters = [
          { isDeleted: isDeleteProps },
          { status: this.removeInvalidBEColumns(this.selectedFilter) },
        ]
      }
      return filters;
    },
    refresh() {
      this.loading = true;
    },
    getUniqueIDValue(transaction) {
      const fileType = this.tableProps.type;
      return transaction[GRID_ACTION_DATA_ELEMENTS[fileType].requestIdKey];
    },
    getUniqueIDKey() {
      const fileType = this.tableProps.type;
      return GRID_ACTION_DATA_ELEMENTS[fileType].requestIdKey;
    },
    setFiltersFromRoute(existingFilters) {
      const filterMapping = ROUTER_PARAMS_GRID_FILTER_MAP[this.tableProps.type];
      if (!filterMapping) {
        return existingFilters;
      }
      for (const [paramKey, filterKey] of Object.entries(filterMapping)) {
        const paramValue = this.routerParams[paramKey];
        if (paramValue) {
          const index = existingFilters.findIndex(item => filterKey in item);
          if (index !== -1) {
            // Update existing filter
            existingFilters.splice(index, 1, { [filterKey]: paramValue });
          } else {
            // Add new filter
            existingFilters.push({ [filterKey]: paramValue });
          }
        }
      }
      return existingFilters;
    },
    setActionData(userId, transaction) {
      var fileName = "";
      const fileType = this.tableProps.type;
      fileName = transaction[GRID_ACTION_DATA_ELEMENTS[fileType].fileNameKey];
      var requestId = "";
      requestId = transaction[GRID_ACTION_DATA_ELEMENTS[fileType].requestIdKey];

      return {
        userId,
        requestId,
        fileName,
        fileType
      };
    },
    async viewYAMLFile(data) {
      try {
        let payload = {
          userId: data.userId,
          type: data.fileType,
          requestId: data.requestId
        };
        const response = await makeOpearationRequest(this.$acclConfigs,
          "/view",
          payload
        );
        if (response.status === 200) {
          this.viewContent = response.data.data;
          this.viewTitle = response.data.header;
          this.showPopup = true;
        }
      } catch (error) {
        console.error('Failed to fetch YAML:', error);
      } finally {

      }
    },
    async bulkDownload(reqIdArr) {
      let data = {
        userId: getCookie(user.id),
        fileType: this.tableProps.type,
        requestId: reqIdArr
      }
      this.download(data, true);
    },
    async download(data, isBulk) {
      window.dispatchEvent(new Event('hide-tooltips'));
      let toastId = null;
      let payload = {
        userId: data.userId,
        type: data.fileType,
        requestIds: isBulk ? data.requestId : [data.requestId],
      };
      let config = {
        responseType: "blob",
      };

      try {
        toastId = this.hardShowLoading("Hang tight! Your download is on its way..");
        const response = await makeOpearationRequest(this.$acclConfigs,
          "/download",
          payload,
          config
        );

        if (response.status === 200) {
          console.log("Converted file download successfully");
          console.log("Response", response);
          // Get the blob from the response
          toast.update(toastId, {
            render: "File downloaded successfully.",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          const blob = new Blob([response.data], { type: response.data.type });
          // Retrieve the fileName
          let fileName = data.fileName;
          console.log("Headers")
          console.log(response)
          const contentDisposition = response.headers.get('content-disposition') || response.headers.get('Content-Disposition');
          console.log("Content Disposition")
          console.log(contentDisposition)
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }
          fileName = fileName ?? "Migrated-Projects";
          // Create a link element and trigger the download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = changeFileExtension(fileName, "zip");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Unexpected response status:", response.status);
          if (toastId != null) {
            toast.update(toastId, {
              render: "Download failed",
              type: "error",
              autoClose: 3000,
              isLoading: false,
            });
          }
          this.showError("Download Failed");
        }
      } catch (error) {
        if (toastId != null) {
          toast.update(toastId, {
            render: "Download failed",
            type: "error",
            autoClose: 3000,
            isLoading: false,
          });
        }

        this.showError("Download Failed");
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async showDifference(comptype, data) {
      this.setType(this.tableProps.type);
      this.setValuesToStore(comptype, data.userId, data.requestId, data.fileName);
      comptype === type.XML ? this.redirectView(pageViews.XMLFILEDIFFERENCE, data) : this.redirectView(pageViews.FILEDIFFERENCE, data);
    },

    async xmlTransform(comptype, data) {
      this.setValuesToStore(comptype, data.userId, data.requestId, data.fileName);
      this.redirectView(pageViews.XMLTRANSFORM, data);
    },

    async trashView(type, userId) {
      this.setValuesToStore(type, userId);
      this.redirectView(pageViews.TRASHVIEW, {});
    },
    async redirectView(viewName, txnData) {
      let gridType = this.tableProps.type;
      redirect(this.tableProps.type, this.$router, this.routerParams, viewName, txnData)
    },
    addFile(event) {
      let droppedFiles = Array.from(event.dataTransfer.files);
      if (!droppedFiles) return;
      this.files = this.files.concat(droppedFiles);
    },
    removeFile(file) {
      this.files = this.files.filter((f) => f !== file);
    },

    formatSize(size) {
      return (size / 1024).toFixed(2);
    },
    onPageChange(page) {
      this.currentPage = page;
      this.refresh();
      this.search();
    },
    onRecordChange(recordSize) {
      this.pageSize = recordSize;
      this.refresh();
      this.search();
    },
    startEditing(data, column) {
      const uniqueId = this.getUniqueIDValue(data);
      this.editingId = uniqueId + column.name;
      if (!(this.originalValues[uniqueId] && this.originalValues[uniqueId].hasOwnProperty(column.name))) {
        if (this.originalValues[uniqueId]) {
          this.originalValues[uniqueId][column.name] = data[column.name];
        } else {
          this.originalValues[uniqueId] = { [column.name]: data[column.name] };
        }
      }
    },
    isEditing(uniqueId, column) {
      return this.editingId === uniqueId + column.name;
    },
    trackChanges(data, column) {
      const uniqueId = this.getUniqueIDValue(data);
      const uniqueIdKey = this.getUniqueIDKey();
      const existingChange = this.changes.find(change => (change[uniqueIdKey] === data[uniqueIdKey]));
      const originalValue = this.originalValues[data[uniqueIdKey]] ? this.originalValues[data[uniqueIdKey]][column.name] : undefined;
      if (data[column.name].toString() !== originalValue.toString()) {
        if (existingChange) {
          if (!existingChange[`current_${column.name}`]) {
            existingChange[`current_${column.name}`] = originalValue.toString();
          }
          existingChange[column.name] = data[column.name];
        } else {
          this.changes.push({ ...data, [`current_${column.name}`]: originalValue.toString() });
        }
      } else {
        this.changes = this.changes
          .map(change => {
            if (change[uniqueIdKey] === data[uniqueIdKey].toString()) {
              const newChange = { ...change };
              Object.keys(change).forEach(key => {
                if (key.startsWith('current_') && key.slice(8) === column.name) {
                  delete newChange[key];
                }
                if (key === column.name) {
                  //delete newChange[key];
                  newChange[key] = data[key];
                }
              });
              return newChange;
            }
            return change;
          })
          .filter(change => {
            return Object.keys(change).some(key => key.startsWith('current_'));
          });
      }
      this.refinedEditableGridViewColumns = EDITABLE_GRID_CHANGES_COLUMNS[this.tableProps.type];
      //console.log("changes =====>", this.changes);
      //this.$emit('editableGridUpdated', this.changes);

      this.refinedEditableGridUpdatesToBeEmitted = {
        "originalChanges": this.changes,
        "refinedChanges": this.refinedChanges,
      }
      this.$emit('editableGridUpdated', this.refinedEditableGridUpdatesToBeEmitted);
    },
    discardEditableGridChanges() {
      this.resetEditableGrid();
      this.refresh();
      this.search();
    },
    resetEditableGrid() {
      this.changes = [];
      this.editingId = null;
      this.originalValues = {};
      this.refinedEditableGridViewColumns = [];
      this.refinedEditableGridUpdatesToBeEmitted = {};
    },
    async submitEditableGridChanges() {
      try {
        const response = await makeOpearationRequest(this.$acclConfigs, EDITABLE_GRID_ACTIONS[this.tableProps.type].ingestionEndpoint, this.changes);
        if (response.status === 200) {
          this.resetEditableGrid();
          this.refresh();
          this.search();
          this.showSuccess(response.data.message);
        } else {
          this.showError(response.data.message);
        }
      } catch (error) {
        console.error("Update Failed:", error);
        throw error;
      } finally {
      }
    },
    getStatusColumns(record) {
      const statusColumns = [
        { icon: require('@/assets/icons/kafka-logo.png'), status: "jmsStatus", accuracy: "jmsAccuracy", routeTo: pageViews.KAFKACONFIGGENERATORVIEW, name: "Kafka" },
        { icon: require('@/assets/icons/Schema.png'), status: "schemaStatus", accuracy: "schemaAccuracy", routeTo: pageViews.SCHEMACONVERTERVIEW, name: "Schema" },
        { icon: require('@/assets/icons/xslticon.png'), status: "xslStatus", accuracy: "xslAccuracy", routeTo: pageViews.CONVERTERVIEW, name: "XSLT" },
        { icon: require('@/assets/icons/Connector.png'), status: "connectorStatus", accuracy: "connectorAccuracy", routeTo: pageViews.CONNECTOR_CONFIG_GENERATOR_VIEW, name: "Connector" },
        { icon: require('@/assets/icons/Mapper.png'), status: "mapperStatus", accuracy: "mapperAccuracy", routeTo: pageViews.MAPPERVIEW, name: "Mapping Docs" },
      ];
      return statusColumns.map(({ icon, status, accuracy, routeTo, name }) => {
        const statusValue = record[status] ? CONVERSION_STATUS.getByValue(record[status]).name : "-";
        const accuracyValue = record[accuracy] ? `${record[accuracy]}` : "0";
        let cssClass = "ok"; // Default green color for OK status
        let strokeDasharray = `${accuracyValue}, 100`; // Default stroke-dasharray for OK status

        if (statusValue === CONVERSION_STATUS.PROGRESS.name) {
          cssClass = CONVERSION_STATUS.PROGRESS.value; // Orange color for in progress
          strokeDasharray = "100, 100"; // Fully filled circle
        } else if (statusValue === CONVERSION_STATUS.NA.name) {
          cssClass = CONVERSION_STATUS.NA.value; // Black color for not applicable
          strokeDasharray = "100, 100"; // Fully filled circle
        } else if (statusValue === CONVERSION_STATUS.FAIL.name) {
          cssClass = CONVERSION_STATUS.FAIL.value; // Red color for failed
          strokeDasharray = "100, 100"; // Fully filled circle
        } else if (statusValue === CONVERSION_STATUS.NEW.name) {
          cssClass = CONVERSION_STATUS.NEW.value; // Blue color for new status
          strokeDasharray = "100, 100"; // Fully filled circle
        }

        return { 
          icon, 
          statusValue, 
          accuracyValue, 
          routeTo, 
          cssClass, 
          strokeDasharray,
          name,
          iconStyle: statusValue === CONVERSION_STATUS.NA.name ? { backgroundColor: '#ccc', cursor: 'not-allowed' } : {}
        };
      });
    },
    async calculateComplexity(data) {
      try {
        this.userId = data.userId;
        this.type = data.type;
        this.showCalcPopup = true;
        this.viewTitle = 'Complexity Calculation';
      } catch (error) {
        console.error('Failed to fetch YAML:', error);
      } finally {

      }
    },
    isCalculateToBeDisabled(transaction) {
      const matchingStatusColumns = STATUS_COLUMNS.filter(key => transaction.hasOwnProperty(key));
      let statusToBeChecked = [];
      if ([type.MAPPER, type.PROJECT].includes(this.tableProps.type)) {
        statusToBeChecked = [CONVERSION_STATUS.OK.value];
      } else {
        statusToBeChecked = [CONVERSION_STATUS.OK.value, CONVERSION_STATUS.NA.value, CONVERSION_STATUS.FAIL.value, CONVERSION_STATUS.PROGRESS.value];
      }
      const atleastOnePassed = matchingStatusColumns.map(key => transaction[key]).some(element => statusToBeChecked.includes(element));
      return !atleastOnePassed;
    },

  },
};
</script>

<style scoped>
/* Media Query Start */

/* X-Small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {
  .filter-label{
    font-size: 15px;
    margin-top: 0px;
  }
 } */

/* Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) { 
  .filter-label{
    font-size: 20px;
  }
 } */

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}

/* Media Query End */

/* Back Button Style Start */

.small-font {
  font-size: 0.7rem;
  margin-bottom: 0;
}

.back-button {
  margin-left: 15%;
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  border: none !important;
  /* padding:  30px !important; */
  -webkit-transition: background-color 1s, color 1s, -webkit-transform 0.5s;
  transition: background-color 1s, transform 0.5s;
  box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
}

.back-button:hover {
  -webkit-transform: translateX(-5px);
}

.fa-3x {
  font-size: 2.5rem;
}

.sortbtn:hover,
.sortbtn {
  background-color: white;
  border-color: white;
  /* border-radius: 6px; */
  height: 5vh;
  width: 8vw;
  color: #da6a26;
  text-align: center;
  font-weight: bolder;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  /* margin-left: 1rem; */
  /* margin-right: 12rem; */
}

.sortlbl {
  text-align: center;
  padding-top: 12px;
  padding-right: 3px;
  padding-left: 2px;
  padding-bottom: 8px;
}

.sortbtn.show {
  background-color: white;
  color: #da6a26;
}

.subitbtn {
  background-color: #da6a26;
  color: white;
  border: none;
  border-radius: 7px;
  height: 6vh;
  width: 10vw;
  text-align: center;
  font-weight: bolder;
}

.sort .sortbtn {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  margin-left: 1rem;
}

.sort {
  width: 22% !important;
  margin-left: 0.8rem;
  background-color: #da6a26;
  color: white;
  border: none;
  align-content: space-evenly;
  border-radius: 7px;
  text-align: left;
  text-justify: left;
  font-weight: bolder;
  /* height: 3vh; */
  /* width: 11vw; */
  float: left;
  padding: 0px 32px 0px 12px !important;
  /* margin-left: -2.5rem; */
}

.label {
  display: none;
  margin-left: 10px;
}

.xml-tranform-sidebar-menu {
  top: 35%;
}

.submitbtn {
  align-content: center;
}

.loading-gif {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 130px;
}

.loading-gif img {
  max-width: 100%;
  /* Ensure the image scales properly */
  max-height: 100%;
  /* Ensure the image scales properly */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

/* loading icon end */

.filterButton {
  /* display: flex; */
  height: 35px;
  background-color: #1D2856 !important;
  /* color: white !important;
  border: none;
  border-radius: 7px;
  text-align: center;
  font-weight: bolder;
  align-items: center;
  justify-content: center; */
}

/* .btn-small {
  --bs-btn-padding-y: 0.55rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.850rem;
    --bs-btn-border-radius: var(--bs-border-radius-sm);
} */

.right-margin {
  margin-right: 1rem;
}

.search-form {
  display: flex;
  align-items: center;
}

.col-sel-btn {
  margin-left: 0.2rem;
}

.constabtn {
  margin-left: 1%;
}

.empty {
  margin-right: -11rem;
}

.srch {
  /* margin-left: 5rem;
  margin-right: 6rem; */
}

.clrbtn {
  margin-left: 7rem;
  width: 7vw;
}


.cus-table-header-sticky {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
  width: 50px !important;
}

.table-header-sticky {
  height: 0%;
  position: sticky;
  top: 0;
  z-index: 1;

}

/* diff container design start */
.diff-container {
  white-space: normal;
  margin-top: 20px;
}

/* Custom styles to decrease line spacing */
.diff-container .d2h-wrapper .d2h-side-by-side .d2h-code-side-line {
  padding: 2px 10px;
  /* Adjust the padding to reduce space */
}

.diff-container .d2h-wrapper .d2h-side-by-side .d2h-code-line {
  margin: 0;
  /* Remove the default margin */
}

.diff-container .d2h-wrapper .d2h-side-by-side td.d2h-code-side-linenumber {
  padding: 2px 5px;
  /* Adjust the padding of line numbers */
}

/* diff container design end */
.fileName-align {
  text-align: left;
}

.table-group-divider span {
  font-size: 10px;
}

/* table design start */

.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: 3px solid #ddd;
  border-radius: 8px;
  table-layout: auto;

  text-align: center;
  position: relative;
  white-space: nowrap;
  overflow-y: auto;

  /*TODO remove flex*/
  flex: 1; /* Take remaining space in the outer div */
  box-sizing: border-box; /* Include padding and border in width/height */
}

.track-changes-table th {
  background-color: lightgrey;
  color: #000;
}

.custom-table th,
.custom-table td {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.nowrap {
  flex-wrap: nowrap;
  display: inline-flex;
}

.custom-table th {
  background-color: #da6a26;
  color: whitesmoke;
}

.table-responsive {
  height: 84vh; /* Full viewport height */
  display: flex; /* Optional: Flex for layout purposes */
  flex-direction: column; /* Stack content vertically */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

table,
td {
  border: 1px;
}

th {
  border: 1px;
  cursor: pointer;
  position: relative;
}

.xml-tranfomer-dropdown-upload-icon {
  margin-left: 0px;
}

.xml-tranfomer-dropdown-para {
  margin-left: 10px;
}

.btn-view {
  background: url("@/assets/icons/view.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  margin-left: 5px;
  height: 23px;
  width: 26px;
  border: none;
}

.btn-download {
  background: url("../assets/icons/download-blue.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  /* Space between icon and text */
  height: 23px;
  width: 26px;
  border: none;
}

.btn-show-difference {
  background: url("../assets/icons/compare.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  height: 23px;
  width: 26px;
  widows: 30px;
  border: none;
  object-fit: cover;
}

.btn-delete {
  background: url("../assets/icons/delete-red2.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  height: 20px;
  width: 26px;
  border: none;
}

.btn-restore {
  background: url("../assets/icons/restorebtn1.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  height: 23px;
  width: 26px;
  border: none;
}

.btn-trash {
  background: url("../assets/icons/recycle-icon.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;

  font-size: 10px;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  border: none;
}

.btn-transform {
  background: url("../assets/icons/transform.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  /* Space between icon and text */
  height: 23px;
  width: 26px;
  widows: 30px;
  border: none;
}

.btn-calculate {
  background: url("../assets/icons/calculator-blue.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  /* Adjust this size as needed */
  margin-left: 5px;
  /* Space between icon and text */
  height: 26px;
  width: 26px;
  border: none;
}


.btn-download:disabled,
.btn-show-difference:disabled,
.btn-delete:disabled,
.btn-restore:disabled,
.btn-trash:disabled,
.btn-transform:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.t .button-group {
  height: 35px;
  float: left;
  margin: 10px;
}

/* .dropdown-toggle {
    float: left;
  } */

.xml-tranfomer-dropdown {
  background: #666;
  height: 100px;
  width: 300px;
  border: #ccc;
}

.xml-tranfomer-dropdown img {
  height: 30%;
  width: 40%;
}

.container {
  max-width: 95%;
  margin-top: 100px;
}

.file-upload {
  width: 20%;
  height: 58vh;
  border: 2px dashed #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  float: left;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.mapper-view .report {
  width: 80%;
  height: 20vh;
  float: left;
}


.report-sub {
  margin-top: 5rem;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  height: 10vh;
  width: 90%;
  border: 2px dashed #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-left: 5%;
}

.table-custom tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.table-custom tbody tr:nth-of-type(even) {
  background-color: #d9d9d9;
}

h1 {
  font-size: 3em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 1vh;
}

h4 {
  font-size: 1.2em;
  font-weight: normal;
  color: #666;
  text-align: center;
  margin-bottom: 1em;
}

.file-upload-sub {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.file-upload:hover {
  border-color: #da6a26;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload button {
  filter: drop-shadow(0 15px 25px rgba(222, 98, 7, 0.15));
  display: inline-block;
  border: none;
  background: #da6a26;
  color: white;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  justify-content: center;
}

.file-upload button:disabled {
  background-color: #ccc;
  /* Change this to your preferred disabled color */
  cursor: not-allowed;
}

.file-upload button:hover {
  background-color: #333;
}

.file-upload ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.file-upload li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.file-upload li:hover {
  background-color: #f1f1f1;
}

.file-upload li button[title="Remove"] {
  background-color: #da6a26;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.file-upload li button[title="Remove"]:hover {
  background-color: #da6a26;
}

.file-upload p {
  color: #030a07;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

nav {
  background-color: #cd7838;
  padding: 1em;
}

ul {
  list-style-type: none;
  padding: 0;
  overflow-y: scroll;
  max-height: 20rem;
}

li {
  /* display: inline; */
  /* margin-right: 1em; */
  margin-top: 1vh;
}

a {
  /* color: white; */
  text-decoration: none;
}

.sticky {
  position: sticky;
  right: 0;
  z-index: 0;
  width: 12%;
  box-shadow: 15px 0 15px -15px #da6a26, -9px 0 8px 0px #a9a9a9;
}

.scroll-class {
  /* height: min-content;
  overflow: scroll; */
  max-height: 150px;
  /* Set your desired max-height */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0;
  margin: 10px 0;
  list-style-type: none;
}

.scroll-class li {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}

.srchip {
  /* padding: 0.375rem 4.75rem */
  width: 22rem;
  /* height: 6vh; */
  margin-right: 0.5rem;
  /* width:15vw !important; */
}

.divalignleft {
  margin-left: -7rem;
}

.clrdiv {
  margin-left: -0.5rem;
}

.svg-button {
  /* margin-top: -1.5rem !important; */
  float: right;
  align-items: center;
  border: none;
  background-color: #da6a26;
  cursor: pointer;
  color: white;
}

.sort-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: flex;
  gap: 0.5em;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #aaa;
  user-select: none;
}

.clear-button:hover {
  color: #000;
}

.grayed-out {
  color: gray;
  opacity: 0.6;
}

.filter-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clear-filter-icon {
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  background: unset;
  transform: translateX(-20px);
}

.clear-filter-icon:hover .filter-icon {
  filter: brightness(1.5);
  transform: scale(1.2);
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
}

/**Styles for actionable and non actionable columns */
.text-success {
  transition: font-size 0.3s ease;
}

.text-danger {
  transition: font-size 0.3s ease;
}

.text-new-status {
  color: #1d2856;
  transition: font-size 0.3s ease;
}

.text-in-progress {
  color: #cd7838;
  transition: font-size 0.3s ease;
}

.text-not-applicable {
  color: #2b3b78;
  transition: font-size 0.3s ease;
}

.actionable:hover {
  font-size: 1.1em;
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-item {
  line-height: 1.4;
}

.getOverrideCssClass {
  text-align: left;
}

.actions-dropdown-toggle {
  background: url("../assets/icons/menu.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer;
  outline: none;
  transition: background-image 0.3s ease;
  height: 26px;
  width: 26px;
  border: none;
}

.actions-dropdown-toggle.show {
  background-image: url("../assets/icons/menu_open.svg");
  transform: rotate(90deg);
}

.icon-row {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

/* Icon style */
.icon {
  width: 24px;
  height: 24px;
}

.gloss-view-content {
  text-align: center;
  display: grid;
}

.code-editor {
  width: 50%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  font-family: monospace;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.code-content {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 300px;
  max-width: fit-content;
  background-color: #dbd8d6;
  color: black;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
}

.toolbar {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.separator {
  height: 2px;
  background-color: #ccc;
  margin: 16px 0;
}

.submit-wrapper {
  margin-left: auto;
}

.discard-button {
  padding: 10px 16px;
  background-color: #dc3545;
  /* Bootstrap danger color */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.discard-button:hover {
  background-color: #c82333;
  /* Darker red on hover */
}

.editable-icon {
  background-image: url("../assets/icons/editable.svg");
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

select {
  border: 1px solid #ccc;
  /* Border color */
  border-radius: 9px;
  /* Rounded corners */
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

select:focus {
  outline: none;
  /* Remove outline */
  box-shadow: 0 0 5px rgba(255, 179, 0, 0.676);
  /* Box shadow on focus */
}

/**In-progress status to blink 
.blink {
  display: inline-block;
  animation: blink 1.5s infinite; 
}
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}*/
</style>
<style>
.custom-tooltip .tooltip-inner {
  background-color: lightgray;
  color: black;
}

.custom-ellipsed-text-tooltip {
  position: absolute;
  background-color: lightgray;
  color: rgba(0, 0, 0, 0.723);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  pointer-events: none;
  white-space: pre-wrap;
}


.status-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  /* Add space between icons */
  vertical-align: middle;
  /* Align icons vertically in the middle */
  z-index: 0;
  /* Ensure icons stay behind the column headers */
}

.status-text {
  visibility: hidden;
  width: auto;
  background-color: lightgray;
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 20%;
  left: -100%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.status-container:hover .status-text {
  visibility: visible;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.btn-item {
  background-color: #da6a26;
  color: white;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin: 1px;
  cursor: pointer;
}

.progress-circle {
  position: relative;
  width: 45px;
  height: 45px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}

.circle.ok {
  stroke: #4caf50;
  /* Green for success */
}

.circle.in-prog {
  stroke: #FFA500;
  /* Orange for in progress */
}

.circle.NA {
  stroke: #000000;
  /* Black for not applicable */
}

.circle.fail {
  stroke: #FF0000;
  /* Red for failed */
}

.circle.new {
  stroke: #1E90FF;
  /* Blue for new */
}

.status-icon {
  transition: transform 0.3s ease;
  margin-right: 15px;
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.status-icon:hover {
  transform: scale(1.8) translateX(-8.0px) translateY(-8.0px);
  cursor: pointer;
}
.custom-row {
  --bs-gutter-x: 1.0rem;
}
</style>
