<template>
    <div class="mapper-view" v-if="status">
        <IndependentAcceleratorsView />
        <ReportTemplate v-if="isGridVisible" :table-props="mappingColumns" :project-param-id="projectParamId"
            :mapper-param-id="mapperParamId" @toggleTabs="toggleTabs" />
        <div class="tabs-container" v-if="showTabs">
            <TabContentView @tab-selected="handleTabSelection" />
            <component :is="currentTabComponent" v-if="currentTabComponent" :projectParamId="projectParamId"
                :mapperParamId="mapperParamId" />
        </div>
    </div>
</template>

<script>
import ReportTemplate from "@/components/ReportTemplate.vue";
import { pageViews, type } from "../utils/util";
import Store from "../vuex/store.js";
import IndependentAcceleratorsView from "./IndependentAcceleratorsView.vue";
import TabContentView from "./TabContentView.vue";
import UsageView from "./UsageView.vue";
import TransitionView from "./TransitionView.vue";
import ActivityTypesView from "./ActivityTypesView.vue";
import FieldMappingView from "./FieldMappingView.vue";
import ComplexityView from "./ComplexityView.vue";
import ComponentView from "./ComponentView.vue";

export default {
    name: pageViews.MAPPERVIEW,
    methods: {
        setValuesToStore(type) {
            Store.commit("setType", type);
        },
        toggleTabs() {
            this.showTabs = !this.showTabs;
            this.isGridVisible = !this.isGridVisible;
            if (this.showTabs) {
                this.isGridVisible = false;
                this.handleTabSelection(pageViews.USAGEVIEW);
            }
        },
        handleTabSelection(view) {
            switch (view) {
                case pageViews.USAGEVIEW:
                    this.currentTabComponent = UsageView;
                    break;
                case pageViews.TRANSITIONVIEW:
                    this.currentTabComponent = TransitionView;
                    break;
                case pageViews.ACTIVITYTYPESVIEW:
                    this.currentTabComponent = ActivityTypesView;
                    break;
                case pageViews.FIELDMAPPINGVIEW:
                    this.currentTabComponent = FieldMappingView;
                    break;
                case pageViews.COMPLEXITYVIEW:
                    this.currentTabComponent = ComplexityView;
                    break;
                case pageViews.COMPONENTVIEW:
                    this.currentTabComponent = ComponentView;
                    break;
                default:
                    this.currentTabComponent = null;
            }
        },
        toggleGridVisibility() {
            this.isGridVisible = !this.isGridVisible;
            if (this.isGridVisible) {
                this.showTabs = false;
                this.handleTabSelection(pageViews.USAGEVIEW);
            }
        }
    },
    mounted() {
        this.setValuesToStore(type.MAPPER);
    },
    provide() {
        return {
            toggleMapperTabs: this.toggleTabs // Provide the function to descendants
        };
    },
    components: {
        ReportTemplate,
        IndependentAcceleratorsView,
        TabContentView,
        UsageView,
        TransitionView,
        ActivityTypesView,
        FieldMappingView,
        ComplexityView,
        ComponentView
    },
    data() {
        return {
            status: true,
            showTabs: false,
            currentTabComponent: null,
            isGridVisible: true,
            uploadConfigs: {
                action: "generator",
                actionLabel: "Generator",
                title: "Mapper Config Generator",
                type: type.MAPPER,
                accept: ".zip",
            },
            mappingColumns: {
                type: type.MAPPER,
                isMultiSelectionAllowed: true,
                defaultSortOrder: { name: "desc", label: "Descending" },
                defaultSortColumn: { name: "requestDate", label: "Request Date" },
                configs: [
                    {
                        name: "mapperId",
                        label: "Mapper ID",
                        order: 2,
                    },
                    {
                        name: "requestDate",
                        label: "Request Date",
                        order: 1,
                        sort: true,
                        type: "date",
                        search: false,
                        default: true
                    },
                    {
                        name: "fileName",
                        label: "File Name",
                        order: 4,
                        sort: true,
                        checked: true,
                        default: true,
                        textAlign: "left",
                    },
                    {
                        name: "status",
                        label: "Status",
                        order: 5,
                        sort: true,
                        default: true,
                        search: false
                    },
                    {
                        name: "lastModifiedDate",
                        label: "Last Modified Date",
                        order: 6,
                        sort: true,
                        type: "date",
                        search: false
                    },
                    {
                        name: "mappingCount",
                        label: "Mapping Count",
                        order: 7,
                        sort: true,
                        search: false,
                        default: true
                    },
                    {
                        name: "transitionCount",
                        label: "Transition Count",
                        order: 8,
                        sort: true,
                        search: false,
                        default: true
                    },
                    {
                        name: "complexity",
                        label: "Complexity",
                        order: 9,
                        search: false,
                        sort: true,
                        default: true
                    },
                    {
                        name: "remarks",
                        label: "Remarks",
                        order: 10,
                        search: false,
                        textAlign: "left",
                        textOverflowStyle: "ellipsis",
                    }
                ],
                actions: {
                    viewPopup: {
                        label: 'View Output'
                    },
                    download: {
                        label: 'Download'
                    },
                    calculate: {
                        label: 'Calculate Complexity'
                    },
                }
            }
        };
    },
    props: ["projectParamId", "mapperParamId"],
}

</script>
<style scoped>
.links-container {
    margin-top: 20px;
    /* Adjust this value as needed */
}

.links-container a {
    margin-right: 10px;
    /* Adjust this value as needed */
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}
</style>
